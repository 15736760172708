import serializers from '@/services/serializers/rooof'
import { request, paginatedRequest, resourceOwnerTypes } from './index'
import { MissingIdError, MissingPayloadError, InvalidOwnerTypeError } from './errors'

const RooofAPI = {
  productSubscriptions: {
    list: async (params = {}) => {
      const response = await request({
        method: 'get',
        url: '/rooof/product_subscriptions/',
        params
      })
      return response.data
    }
  },

  featureSubscriptions: {
    list: async (params = {}) => {
      const response = await request({
        method: 'get',
        url: '/rooof/feature_subscriptions/',
        params
      })
      return response.data
    }
  },

  companies: {
    list: async (params = {}) => {
      const response = await request({
        method: 'get',
        url: '/rooof/companies/',
        params
      })
      const data = []

      for (const company of response.data) {
        data.push(serializers.deserializeCompany(company))
      }

      return data
    },
    summary: async (params = {}) => {
      const response = await request({
        method: 'get',
        url: '/rooof/companies/summary',
        params
      })
      return response.data
    },
    propertySummary: async (id, params) => {
      if (!id) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'get',
        url: `/rooof/companies/${id}/properties/summary`,
        params
      })
      return response.data
    },
    billingRates: async (params) => {
      if (!params) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'get',
        url: '/rooof/companies/monthlyrates/',
        params
      })
      return response.data
    },
    agency: async (params = {}) => {
      const response = await request({
        method: 'get',
        url: '/rooof/companies/agency/',
        params
      })
      return response.data
    },
    retrieve: async id => {
      if (!id) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'get',
        url: `/rooof/companies/${id}/`
      })
      return serializers.deserializeCompany(response.data)
    },
    permissionsList: async id => {
      if (!id) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'get',
        url: `/rooof/companies/${id}/permissions/`
      })
      return response.data
    },
    create: async data => {
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'post',
        url: '/rooof/companies/',
        data: serializers.serializeCompany(data)
      })
      return serializers.deserializeCompany(response.data)
    },
    update: async (id, data) => {
      if (!id) {
        throw new MissingIdError()
      }
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'put',
        url: `/rooof/companies/${id}/`,
        data: serializers.serializeCompany(data)
      })
      return serializers.deserializeCompany(response.data)
    },
    partialUpdate: async (id, data) => {
      if (!id) {
        throw new MissingIdError()
      }
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'patch',
        url: `/rooof/companies/${id}/`,
        data: serializers.serializeCompany(data)
      })
      return serializers.deserializeCompany(response.data)
    },
    delete: async id => {
      if (!id) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'delete',
        url: `/rooof/companies/${id}/`
      })
      return response
    },
    cancel: async (id, data) => {
      if (!id) {
        throw new MissingIdError()
      }
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'put',
        url: `/rooof/companies/${id}/cancel/`,
        data
      })
      return response.data
    }
  },

  properties: {
    list: async (params = {}) => {
      const url = params.company ? `/rooof/companies/${params.company}/properties/` : '/rooof/properties/'
      const pageSize = 100
      const response = await paginatedRequest(url, pageSize, params)
      const data = []

      for (const property of response.data) {
        if (property.name === 'Unknown Property') {
          continue
        }
        data.push(serializers.deserializeProperty(property))
      }

      return data
    },
    listPage: async params => {
      const response = await request({
        method: 'get',
        url: '/rooof/properties/',
        params
      })
      response.data.data = []

      for (const property of response.data.results) {
        if (property.name === 'Unknown Property') {
          response.data.count -= 1
          continue
        }
        response.data.data.push(serializers.deserializeProperty(property))
      }

      return response.data
    },
    retrieve: async id => {
      if (!id) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'get',
        url: `/rooof/properties/${id}/`
      })
      return serializers.deserializeProperty(response.data)
    },
    permissionsList: async id => {
      if (!id) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'get',
        url: `/rooof/properties/${id}/permissions/`
      })
      return response.data
    },
    create: async data => {
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'post',
        url: '/rooof/properties/',
        data: serializers.serializeProperty(data)
      })
      return serializers.deserializeProperty(response.data)
    },
    update: async (id, data) => {
      if (!id) {
        throw new MissingIdError()
      }
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'put',
        url: `/rooof/properties/${id}/`,
        data: serializers.serializeProperty(data)
      })
      return serializers.deserializeProperty(response.data)
    },
    partialUpdate: async (id, data, shouldSerialize = true) => {
      if (!id) {
        throw new MissingIdError()
      }
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'patch',
        url: `/rooof/properties/${id}/`,
        data: shouldSerialize ? serializers.serializeProperty(data) : data
      })
      return serializers.deserializeProperty(response.data)
    },
    delete: async id => {
      if (!id) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'delete',
        url: `/rooof/properties/${id}/`
      })
      return response
    },
    cancel: async (id, data) => {
      if (!id) {
        throw new MissingIdError()
      }
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'put',
        url: `/rooof/properties/${id}/cancel/`,
        data
      })
      return response.data
    },
    billingHistory: async id => {
      if (!id) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'get',
        url: `/rooof/properties/${id}/billing-history/`
      })
      return response.data
    }
  },

  products: {
    list: async () => {
      const response = await request({
        method: 'get',
        url: '/rooof/products/'
      })
      return response.data
    },
    retrieve: async id => {
      if (!id) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'get',
        url: `/rooof/products/${id}/`
      })
      return response.data
    },
    create: async data => {
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'post',
        url: '/rooof/products/',
        data
      })
      return response.data
    },
    update: async (id, data) => {
      if (!id) {
        throw new MissingIdError()
      }
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'put',
        url: `/rooof/products/${id}/`,
        data
      })
      return response.data
    },
    partialUpdate: async (id, data) => {
      if (!id) {
        throw new MissingIdError()
      }
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'patch',
        url: `/rooof/products/${id}/`,
        data
      })
      return response.data
    },
    delete: async id => {
      if (!id) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'delete',
        url: `/rooof/products/${id}/`
      })
      return response
    }
  },

  features: {
    list: async () => {
      const response = await request({
        method: 'get',
        url: '/rooof/features/'
      })
      return response.data
    },
    retrieve: async id => {
      if (!id) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'get',
        url: `/rooof/features/${id}/`
      })
      return response.data
    },
    create: async data => {
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'post',
        url: '/rooof/features/',
        data
      })
      return response.data
    },
    update: async (id, data) => {
      if (!id) {
        throw new MissingIdError()
      }
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'put',
        url: `/rooof/features/${id}/`,
        data
      })
      return response.data
    },
    partialUpdate: async (id, data) => {
      if (!id) {
        throw new MissingIdError()
      }
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'patch',
        url: `/rooof/features/${id}/`,
        data
      })
      return response.data
    },
    delete: async id => {
      if (!id) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'delete',
        url: `/rooof/features/${id}/`
      })
      return response
    }
  },

  agreements: {
    list: async (ownerId, ownerType) => {
      if (!ownerId) {
        throw new MissingIdError()
      }
      if (!resourceOwnerTypes.includes(ownerType)) {
        throw new InvalidOwnerTypeError()
      }
      const response = await request({
        method: 'get',
        url: `/rooof/${ownerType}/${ownerId}/agreements/`
      })
      const data = []
      for (const agreement of response.data) {
        data.push(serializers.deserializeAgreement(agreement))
      }

      return data
    },
    create: async (ownerId, ownerType, data) => {
      if (!ownerId) {
        throw new MissingIdError()
      }
      if (!resourceOwnerTypes.includes(ownerType)) {
        throw new InvalidOwnerTypeError()
      }
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'post',
        url: `/rooof/${ownerType}/${ownerId}/agreements/`,
        data: data
      })
      return serializers.deserializeAgreement(response.data)
    },
    delete: async (ownerId, ownerType, agreementId) => {
      if (!ownerId || !agreementId) {
        throw new MissingIdError()
      }
      if (!resourceOwnerTypes.includes(ownerType)) {
        throw new InvalidOwnerTypeError()
      }
      const response = await request({
        method: 'delete',
        url: `/rooof/${ownerType}/${ownerId}/agreements/${agreementId}/`
      })
      return response
    }
  },

  announcements: {
    list: async () => {
      const response = await request({
        method: 'get',
        url: '/rooof/announcements/'
      })
      return response.data
    },
    partialUpdate: async (id, data) => {
      if (!id) {
        throw new MissingIdError()
      }
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'patch',
        url: `/rooof/announcements/${id}/`,
        data
      })
      return response.data
    },
    delete: async id => {
      if (!id) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'delete',
        url: `/rooof/announcements/${id}/`
      })
      return response
    }
  },

  newsletter: {
    create: async email => {
      if (!email) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'post',
        url: '/rooof/email/newsletter',
        data: email
      })
      return response
    }
  },

  config: {
    retrieve: async companyId => {
      if (!companyId) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'get',
        url: `/rooof/config/${companyId}/`
      })
      return response.data
    },
    partialUpdate: async (companyId, data) => {
      if (!companyId) {
        throw new MissingIdError()
      }
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'patch',
        url: `/rooof/config/${companyId}/`,
        data: data
      })
      return response.data
    }
  }
}

export default RooofAPI
